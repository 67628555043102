import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as classes from './BuyToto.module.css';
import { getImageByKey } from "../../../scripts/getImageByKey";
import toto from '../../../images/product/toto.jpg';

const BuyToto = (props) => {

    const { t } = useTranslation();

    const history = useHistory();

    // `/${props.settings.routes.purchaseRoute}/asset/${chosenItem.id}`
    return (
        <div className={classes.BuyTotoContainer}>
            <div className={classes.item}>
                <div className={`${classes.title} font-600`}>Tilaa Hevosviihde TotoTV</div>
                <div className={classes.innerContainer}>
                    <img className={classes.image} src={toto} alt='' />
                    <div className={classes.itemBottomContainer}>
                        <div className={`${classes.text} font-400`}>
                            Kanava hevosurheilun ystävälle – vuosittain yli 2000 suoraa lähetystä.
                        </div>
                        <div className={classes.itemBottomBuySection}>
                            <div className={`${classes.priceContainer} font-600`}>
                                <div className={classes.price}>
                                    {"18"}
                                </div>
                                <div className={classes.monthly}>€/KK</div>
                                <div className={`${classes.vat} font-400`}>ALV 24%</div>
                            </div>
                            <button
                                className={classes.buyButton}
                                onClick={() => history.push(`/${props.routes.packages}/channel/409228`)}
                            >
                                {t("buyToto.checkAndBuy")}
                            </button>
                        </div>

                    </div>
                </div>
            </div>

            <div className={classes.item}>
                <div className={`${classes.title} font-600`}>Tilaa Hevosviihde-boxi</div>
                <div className={classes.innerContainer}>
                    <img className={classes.image} src={getImageByKey("dune1")} alt='' />
                    <div className={classes.itemBottomContainer}>
                        <div className={`${classes.text} font-400`}>
                            Mahdollistaa jopa HD-tasoiset lähetykset
                        </div>
                        <div className={classes.itemBottomBuySection}>
                            <div className={`${classes.priceContainer} font-600`}>
                                <div className={classes.price}>
                                    {"139€"}
                                </div>
                                <div className={`${classes.vat} font-400`}>ALV 24%</div>
                            </div>
                            <button
                                className={classes.buyButton}
                                onClick={() => history.push(`/${props.routes.packages}/asset/367917`)}
                            >
                                {t("buyToto.checkAndBuy")}
                            </button>
                        </div>

                    </div>
                </div>
            </div>


        </div >
    );
}

export default BuyToto;