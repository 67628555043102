import { useMyContext } from '../../../contexts/StateHolder';
//import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { convertDate } from '../../../scripts/utils';
//import { changePassword, updateUser } from "../../scripts/dataHandlers";
import ProfileHeader from '../ProfileHeader';
import * as classes from '../../../Shared/Input/Input.module.css';
import * as classes2 from './CurrentSubscription.module.css';

const CurrentSubscription = (props) => {
  // Bring stateholders from context
  const { user } = useMyContext();

  // Setup translate function
  const { t } = useTranslation();

  const subIndex = user.buyerProducts?.findIndex(
    (product) => product.name === 'Hevosviihde TotoTV'
  );
  const assetIndex = user.buyerProducts?.findIndex(
    (product) => product.name === 'Hevosviihde boxi'
  );

  return user?.buyerProducts?.length > 0 ? (
    <div className={classes.formProfilePrimary}>
      <div className={classes.formSecondary}>
        <form className={classes.formBlog}>
          <ProfileHeader showSubscriptions={props.showSubscriptions} />
          {subIndex !== -1 ? (
            <>
              <div className={classes2.title}>{`${t(
                'subscriptions.subTitle'
              )}:`}</div>
              <div className={classes2.item}>
                <div className={`${classes2.itemName} font-400`}>
                  {user.buyerProducts[subIndex].name}
                </div>
                <div className={classes2.inner}>
                  <div className={classes2.subTitle}>{`${t(
                    'subscriptions.activeTill'
                  )}:`}</div>
                  <div className={classes2.dateRow}>
                    {convertDate(user.buyerProducts[subIndex].validFrom)}
                    <div className={classes2.separator}>-</div>
                    {convertDate(user.buyerProducts[subIndex].validTo)}
                  </div>
                  <div className={classes2.subTitle}>{`${t(
                    'subscriptions.nextPayment'
                  )}:`}</div>
                  {convertDate(user.buyerProducts[subIndex].nextPaymentDate)}
                </div>
              </div>
            </>
          ) : null}
          {assetIndex !== -1 ? (
            <>
              <div className={classes2.title}>{`${t(
                'subscriptions.assetTitle'
              )}:`}</div>
              <div className={classes2.item}>
                <div className={`${classes2.itemName} font-400`}>
                  {user.buyerProducts[assetIndex].name}
                </div>
                {convertDate(user.buyerProducts[assetIndex].validFrom)}
              </div>
            </>
          ) : null}
        </form>
      </div>
    </div>
  ) : (
    <div className={classes.formProfilePrimary}>
      <div className={classes.formSecondary}>
        <form className={classes.formBlog}>
          <ProfileHeader showSubscriptions={props.showSubscriptions} />
          <div className={classes2.placeholder}>
            {t('subscriptions.placeholder')}
          </div>
        </form>
      </div>
    </div>
  );
};

export default CurrentSubscription;
