import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { convertCurrency } from '../../../scripts/utils';
import * as classes from './PackageComponent.module.css';
import { useState } from 'react';

//shows single package information. Doesn't handle ticket packages or f-liiga single event purchase packages yet.
const PackageComponent = (props) => {
  const { pkg, buyButtonAction, userPackages } = props;

  const { t } = useTranslation();

  const [textDexcription, setTextDexcription] = useState(null);
  const [seeMore, setSeeMore] = useState(false);

  const hello = () => {
    console.log(seeMore);
    setSeeMore(true);
  };
  useEffect(() => {
    if (pkg) {
      const trancuate = (text, size) => {
        if (text.length > size) {
          return `${text.substring(0, size)} <p class="SeeMore">...SeeMore</p>`;
        } else {
          return text;
        }
      };
      let text;
      text = trancuate(pkg.description, 300);

      !seeMore ? setTextDexcription(text) : setTextDexcription(pkg.description);
    }
  }, [pkg, seeMore]);

  return (
    <div className={classes.packageComponent} key={pkg.id}>
      <div className={classes.packageTitle}>{pkg.name}</div>
      <div className={classes.packagePrice}>
        {pkg.price} {convertCurrency(pkg.currency)}
      </div>
      <div
        className={`${classes.packageDescription} font-300`}
        dangerouslySetInnerHTML={{ __html: textDexcription }}
        onClick={hello}
      ></div>
      <div className={classes.packageBuyButtonContainer}>
        {userPackages.findIndex(
          (userPkg) =>
            userPkg.sku === pkg.sku &&
            userPkg.validFrom < Date.now() &&
            userPkg.validTo > Date.now()
        ) !== -1 ? (
          <div
            className={classes.packageBoughtButton}
            id={pkg.id}
            name={pkg.id}
          >
            <div className={classes.packageBoughtButtonText}>
              {t('packages.Bought')}
            </div>
          </div>
        ) : (
          <button
            className={classes.packageBuyButton}
            id={pkg.id}
            name={pkg.id}
            onClick={() => buyButtonAction(pkg)}
          >
            {t('packages.Order')}
          </button>
        )}
      </div>
    </div>
  );
};

export default PackageComponent;
